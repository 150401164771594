<template>
    <div class="wrap">
        <div class="loading">
            <div v-if="showIcon" class="bounceball"></div>
            <div v-if="showText" class="text">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        showIcon: {
            type: Boolean,
            default: true,
        },
        showText: {
            type: Boolean,
            default: true,
        },
        text: {
            type: String,
            default: 'NOW LOADING',
        },
    },
};
</script>

<style lang="scss" scoped>
$font-family-primary: 'Roboto';
$font-family-secondary: 'Roboto Condensed';
$bauer-blue: #009acd;

.wrap {
    font-family: $font-family-primary;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    color: $bauer-blue;
    display: inline-block;
    margin-left: 5px;
}

.bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px;
    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $bauer-blue;
        transform-origin: 50%;
        animation: bounce 500ms alternate infinite ease;
    }
}

@keyframes bounce {
    0% {
        top: 30px;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        transform: scaleX(2);
    }
    35% {
        height: 15px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0;
    }
}
</style>
